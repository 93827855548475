<template>
  <div class="register-funnel login-funnel forgot-password-funnel">
    <div class="register-funnel__main-block">
      <div class="register-funnel__main-block__inner">
        <div class="register-funnel__main-block__heading">
          <a href="https://www.letsconnect.at/" class="register-funnel__main-block__heading__logo-wrapper">
            <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="register-funnel__main-block__heading__logo-img" />
          </a>

          <div class="register-funnel__main-block__heading__auth">
            <img src="@/assets/images/register-funnel/lock.png" alt="Secure" class="register-funnel__main-block__heading__auth__icon" />

            <div class="register-funnel__main-block__heading__auth__link" @click="$router.push('/pages/login')">
              {{ $t('vue.login') }}
            </div>
          </div>
        </div>

        <div class="register-funnel__main-block__body">
          <div class="register-funnel__main-block__flow">
            <div class="register-funnel__main-block__flow__heading">
              <div class="register-funnel__main-block__flow__heading__title">{{ $t('vue.forgotYourPassword') }}</div>
              <div class="register-funnel__main-block__flow__heading__description">{{ $t('vue.recoverYourPasswordText') }}</div>
            </div>

            <div class="register-funnel__main-block__flow__content">
              <div class="register-funnel__main-block__flow__content__form">
                <div class="register-funnel__main-block__flow__content__form__fields">
                  <div class="register-funnel__main-block__flow__content__form__fields__field">
                    <div class="register-funnel__main-block__flow__content__form__fields__field__inner">
                      <input
                        ref="email"
                        type="email"
                        class="register-funnel__main-block__flow__content__form__fields__field__input"
                        v-model="email"
                        v-validate="'required|email'"
                        data-vv-validate-on="blur"
                        :name="$t('inputs.email')"
                        :label-placeholder="$t('inputs.email')"
                      />

                      <div
                        class="register-funnel__main-block__flow__content__form__fields__field__label"
                        :class="{
                          'register-funnel__main-block__flow__content__form__fields__field__label--moved': email && email.length > 0
                        }"
                        @click="$refs.email.focus()"
                      >
                        {{ $t('inputs.enterYourEmail') }}
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.email'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.email')) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="register-funnel__main-block__flow__content__next-button-wrapper">
              <div class="register-funnel__main-block__flow__content__next-button" @click="recoverPassword">
                {{ $t('vue.resetPassword') }}

                <img
                  src="@/assets/images/register-funnel/forward-arrow.png"
                  alt="Secure"
                  class="register-funnel__main-block__flow__content__next-button__icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'

export default {
  data() {
    return {
      email: '',
      passwordResetEmailSent: false
    }
  },

  async created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },

  methods: {
    async recoverPassword() {
      const result = await this.$validator.validateAll()

      if (!result) {
        return
      }

      try {
        this.$vs.loading()

        const forgotPassword = this.$functions.httpsCallable('forgotPassword')
        await forgotPassword({
          email: this.email,
          locale: this.$i18n.locale
        })

        setTimeout(() => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.passwordResetEmailSent'),
            text: this.$i18n.t('vue.emailResetPasswordSent'),
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
        }, 100)

        this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })

        this.$vs.loading.close()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}

.register-funnel {
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-feature-settings: 'ss01' on;
  font-family: 'Larsseit-Regular';

  &__main-block {
    padding-bottom: 20px;
    width: calc(100% - 400px);
    background: rgb(255, 255, 255);

    @media (max-width: 991px) {
      width: 100%;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding: 0 20px;
      max-width: 1140px;
      height: 100%;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 19px 0;
      width: 100%;

      @media (max-width: 991px) {
        padding: 15px 0 20px;
      }

      &__logo {
        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-img {
          width: 150px;

          @media (max-width: 991px) {
            width: 119px;
            height: 49px;
          }
        }
      }

      &__auth {
        display: flex;
        align-items: center;

        &__text {
          color: rgb(83, 83, 91);
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.05px;

          @media (max-width: 991px) {
            display: none;
          }
        }

        &__icon {
          margin-left: 13px;
          width: 11px;
        }

        &__link {
          margin-left: 5px;
          color: rgb(48, 52, 247);
          font-family: 'Larsseit-Bold';
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 0.05px;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &__body {
      position: relative;
    }

    &__flow {
      position: relative;
      margin: 80px auto 0;
      width: 480px;
      max-width: 100%;

      @media (max-width: 991px) {
        margin-top: 0;
      }

      &__heading {
        margin: 45px auto 0;

        @media (max-width: 991px) {
          margin-top: 20px;
          max-width: 240px;
        }

        &__title {
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Bold';
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          letter-spacing: -0.177778px;

          @media (max-width: 991px) {
            font-size: 30px;
            line-height: 36px;
            letter-spacing: -0.166667px;
          }
        }

        &__description {
          margin-top: 16px;
          margin-bottom: 64px;
          color: rgb(38, 38, 41);
          font-family: 'Larsseit-Medium';
          font-size: 20px;
          line-height: 22px;
          letter-spacing: -0.111111px;
          text-align: center;

          @media (max-width: 991px) {
            margin-bottom: 38px;
          }
        }
      }

      &__content {
        &__separator {
          position: relative;
          margin: 30px 0;
          color: rgb(83, 83, 91);
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.1px;
          text-align: center;

          @media (max-width: 991px) {
            margin: 20px 0;
            font-size: 16px;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1px;
            width: 100%;
            background: #d9dbdd;
          }

          &__label {
            display: inline-block;
            position: relative;
            padding: 0 14px;
            background: rgb(255, 255, 255);
          }
        }

        &__form {
          &__link {
            display: inline-block;
            color: rgb(48, 52, 247);
            font-size: 12px;
            line-height: 15px;

            &:hover {
              text-decoration: underline;
            }

            &-wrapper {
              text-align: right;
            }
          }

          &__fields {
            &__field {
              margin-top: 28px;

              @media (max-width: 991px) {
                margin-top: 10px;
              }

              &:first-of-type {
                margin-top: 0;
              }

              &__inner {
                position: relative;
              }

              &__label {
                position: absolute;
                top: 50%;
                left: 20px;
                padding: 0 2px;
                touch-action: none;
                color: #878894;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.111111px;
                background: #ffffff;
                transform: translateY(-50%);

                &--moved {
                  top: 0;
                  left: 15px;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  transition: all 0.2s linear;
                }
              }

              &__input {
                padding: 5px 20px;
                width: 100%;
                height: 60px;
                color: #35495e;
                font-family: 'Larsseit-Regular';
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.111111px;
                background: rgb(255, 255, 255);
                border: 1px solid rgb(217, 219, 221);
                border-radius: 8px;

                /* transition is using for preventing the autofill styles of the input */
                transition: background-color 99999999ms;

                &:focus + .register-funnel__main-block__flow__content__form__fields__field__label,
                &:-webkit-autofill + .register-funnel__main-block__flow__content__form__fields__field__label {
                  top: 0;
                  left: 15px;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  transition: all 0.2s linear;
                }
              }
            }
          }
        }

        &__next-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 43px;
          color: rgb(255, 255, 255);
          font-family: 'Larsseit-Bold';
          font-size: 20px;
          line-height: 18px;
          letter-spacing: -0.111111px;
          background: rgb(48, 52, 247);
          border: none;
          border-radius: 8px;

          @media (max-width: 991px) {
            width: 100%;
          }

          &:hover {
            cursor: pointer;
            background: rgba(48, 52, 247, 0.9);
          }

          &-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 50px;

            @media (max-width: 991px) {
              margin-top: 20px;
            }
          }

          &__icon {
            margin-left: 17px;
            width: 20px;
          }
        }
      }
    }

    &__footer {
      margin-top: 28px;

      @media (max-width: 991px) {
        margin-top: 20px;
      }

      &__description {
        color: rgb(135, 136, 148);
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.0833333px;

        &__link {
          display: inline-block;
          color: rgb(48, 52, 247);

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &--hover {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &__trust-service {
        margin-top: 40px;
        text-align: center;
      }
    }
  }
}

.register-funnel.login-funnel {
  .register-funnel {
    &__main-block {
      width: 100%;
    }
  }
}

.register-funnel.forgot-password-funnel {
  .register-funnel {
    &__main-block {
      width: 100%;

      &__flow {
        &__heading {
          @media (max-width: 991px) {
            max-width: 100%;
          }

          &__description {
            color: rgb(83, 83, 91);
            font-family: 'Larsseit-Regular';
            font-size: 18px;
            line-height: 20px;
            letter-spacing: -0.1px;
            text-align: center;

            @media (max-width: 991px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
